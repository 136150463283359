body{
    background-size: 100vw;
    background-repeat: no-repeat, repeat;
    -webkit-font-smoothing: initial!important;
}
h4, h2 {
    color: black!important;
    font-family: verdana, geneva, sans-serif!important;
    font-weight: 700!important;
    padding-left:10px; padding-right:10px;
    margin-bottom: 20px!important;
    text-align: center;
}
h3 {
    color: black!important;
    font-family: verdana, geneva, sans-serif!important;
    font-weight: 700!important;
    padding-left:10px; padding-right:10px;
    text-align: center;
}

li {
    margin-bottom: 5px;
    font-size: unset;
}
p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5
}
img {
    max-width: 700px;
}

body,
table,
div,
td {
    font-family: Verdana;
}

.Header, .TypeOfEvent, .DateTime {
    padding-left: 40px;
}
.Header p, .DateTime p, TypeOfEvent p {
    line-height: 1.2!important;
}
.DateTime p:first-child {
    font-weight: 700;
}
.Footer, .Details {
    padding-left: 40px;
    padding-right: 40px;
}
.Details p {
    line-height: 22px!important;
}
.Details img, .SignupForm img {
    width: 100%
}
.Confirmation p {
    line-height: 21.88px;
}
.AboutTillots p {
    font-size: 11px;
    line-height: 13.37px;
    color: #3C3D3F;
}

#form, #surveyForm {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}
.SignupForm, .SurveyForm {
    border-top: 0;
    border-bottom: 0;
    background-color: rgba(60, 61, 63, 0.08)
}
@media only screen and (min-width: 480px) {
    h4 {
        font-size: 12pt!important;
    }
    h3 {
        font-size: 18pt!important;
    }
    h2 {
        font-size: 22pt!important;
    }
}
@media only screen and (max-width: 480px) {
    h4 {
        font-size: 9pt!important;
    }
    h3 {
        font-size: 13pt!important;
    }
    h2 {
        font-size: 19pt!important;
    }
}
img.SmallLogoImage {
    padding-right: 20px;
}
@media only screen and (max-width: 600px) {
    img.SmallLogoImage {
        width: 100%;
    }
    img.LogoImage {
        display: none;
    }
    .HeaderLeftColumn {
        max-width: 100vw!important;
    }
    .Header, .TypeOfEvent, .DateTime {
        padding-left: 20px;
    }
    #form, #surveyForm, .ConfirmationWrapper {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .Footer, .Details {
        padding-left: 20px;
        padding-right: 20px;
    }
    .RatingLabel {
        margin-left: 0px!important;
        margin-right: 5px!important;
    }
    .RatingLabel:last-child {
        margin-left: 0px!important;
        margin-right: 0px!important;
    }
    .RatingRadio {
        padding: 0px!important;
    }
    main {
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .PaperBox {
        margin-top: 0!important
    }
    .SmallLogo {
        background-image: url("https://test.upload.eventmanager.propharma.se/template/61e8fc807aa7c6c11f95e308/Swosh_small.svg");
        background-position: right;
        background-repeat: no-repeat;
        width: 100%;
        height: 100px
    }
}

.Agenda table td:nth-child(1) {
    padding-right: 10px;
    min-width: 60px;
    vertical-align: top;
    padding-bottom: 10px;
}

.Agenda table td:nth-child(2) {
    font-weight: 700;
    vertical-align: top;
    padding-bottom: 10px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    .MuiPaper-root {
        box-shadow: none!important; /* Tar bort skuggor vid utskrift */
        padding: 5px!important;
    }
    .PrintPaper {
        padding: 5px!important;
    }
}